@import url("./color-picker-styling-overide.css");
@import url("./date-picker-styling-overide.css");

:root {
  --color-medium-grey: rgb(151, 151, 151);
  --color-dark-grey: rgb(136, 136, 136);
  --color-light-grey: rgb(238, 238, 238);
  --color-off-white: rgb(251, 251, 251);
  --color-disabled-grey: rgb(200, 200, 200);
  --color-light-deeper-grey: rgb(223, 223, 223);
  --color-hover-light-blue: rgb(230, 247, 255);
  --color-input-font-title-black: rgb(40, 45, 50);
  --color-almost-black: rgb(40, 45, 50);
  --color-black: rgb(0, 0, 0);
  --color-blue: rgb(74, 144, 226);
  --color-hover-dark-blue: rgb(24, 144, 255);
  --color-border-blue: rgb(64, 169, 255);
  --color-green: rgb(56, 177, 58);
  --color-selected-yellow: rgb(255, 255, 179);
  --color-action-red: rgb(176, 28, 36);
  --color-notification-red: rgb(220, 25, 40);

  --color-primary-blue: #4A90E2;
  --color-primary-dark: #282D32;
  --color-mid-grey: #979797;
  --color-gray: #f4f4f5;
  --color-white: #FFFFFF;
  --color-confirmation-green: #38B13A;
  --color-warning-yellow: #F5A623;
  --color-error-red: #D0021B;
  --color-line-grey: #E4E4E4;
  --color-button-border: #D9D9D9;
  --color-transparent: rgba(0, 0, 0, 0);
  --color-overlay-dark: rgba(0, 0, 0, 0.7);
  --color-box-shadow: rgba(0, 0, 0, 0.5);
  --color-card-border: #DBDBDB;
  --color-card-shadow: rgba(0, 0, 0, 0.15);
  --color-title-grey: #282d32;

  --bg-color-label-default: #282D32;
  --bg-color-label-amber: #fdf2e0;
  --bg-color-label-blue: #4A90E2;
  --bg-color-label-green: #dcefe2;
  --bg-color-label-red: #F8DBDF;

  --font-size-page-title: 1.75rem;
  --font-size-success-number: 1.5rem;
  --font-size-H1: 1.25rem;
  --font-size-H2: 1rem;
  --font-size-H3: 0.875rem;
  --font-size-H4: 0.75rem;
  --font-size-H5: 0.625rem;
  --font-size-body: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-sm: 0.85rem;
  --font-size-xs: 0.75rem;
  --font-size-xxs: 0.65rem;
  --font-size-button: 0.75rem;
  --font-size-label: 0.75rem;
  --font-size-error: 0.75rem;
  --font-size-tooltip: 0.875rem;
  --font-size-address: 0.875rem;
  --font-size-link: 0.875rem;
  --font-size-subtitle: 0.875rem;
  --font-size-footer: 0.625rem;
  --font-info-panel: 1.125rem;


  --font-size-H1-mobile: 1.25rem;
  --font-size-H2-mobile: 1rem;
  --font-size-H3-mobile: 0.75rem;
  --font-size-H4-mobile: 0.75rem;
  --font-size-body-mobile: 0.875rem;
  --font-size-error-mobile: 0.875rem;
  --font-size-label-mobile: 0.725rem;
  --font-size-footer-mobile: 0.625rem;
  --font-size-checkbox-mobile: 0.575rem;

  --font-weight-H1: 700;
  --font-weight-H2: 700;
  --font-weight-H3: 400;
  --font-weight-H4: 600;
  --font-weight-body: 400;
  --font-weight-error: 700;
  --font-weight-footer: 500;

  --global-input-bottom-margin: 15px;

  --spacing-modal: 20px 35px;
  --spacing: 20px;
  --spacing-medium: 15px;
  --spacing-small: 10px;
  --spacing-extra-small: 5px;
  --spacing-page: 5px 20px 30px;
  --spacing-margin-page: 10px 0 20px 0;
  --border-radius: 3px;
  --border-radius-small: 2px;


  --box-shadow: rgb(30 30 30 / 5%) -10px 10px 20px 0px;

  --font-family-body: 'Gotham Book';
  --font-family-heading: 'Gotham Medium';
  --font-family-italic: 'Gotham Ultra Italic';
  --font-family-body-sans: 'PublicSans';

  /* z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;

}

@font-face {
  font-family: 'Gotham Book';
  src: url('/fonts/gotham-book.otf');
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('/fonts/Gotham-Medium.woff');
}

@font-face {
  font-family: 'Gotham Ultra Italic';
  src: url('/fonts/Gotham-UltraItalic.woff');
}

@font-face {
  font-family: 'Gotham Black';
  font-weight: bold;
  src: url('/fonts/Gotham-Black.woff');
}

@font-face {
  font-family: 'PublicSans';
  src: url('/fonts/PublicSans-Normal.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'PublicSans';
  src: url('/fonts/PublicSans-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'PublicSans';
  src: url('/fonts/PublicSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'PublicSans';
  src: url('/fonts/PublicSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

a {
  color: var(--color-primary-dark);
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: var(--font-family-body), 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: var(--color-white);
}

textarea,
input,
button {
  font-family: var(--font-family-body), serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.global-bold {
  font-weight: var(--font-weight-H1);
}

* {
  box-sizing: border-box;
}

body.rpa-modal-open {
  overflow-y: hidden;
}

.global-title {
  font-size: var(--font-size-page-title);
  font-weight: var(--font-weight-H1);
  color: var(--color-primary-dark);
  line-height: 30px;
  padding: 0 0 10px;
}

.global-display-card-title {
  font-size: var(--font-size-H1);
  font-weight: var(--font-weight-H1);
  color: var(--color-primary-dark);
  line-height: 30px;
  padding: 8px 0 0;
}

.global-display-card-description {
  font-size: var(--font-size-address);
  font-weight: var(--font-weight-body);
  line-height: 1.4;
  color: var(--color-primary-dark);
  padding: 8px 0;
}

.global-display-card-link {
  font-size: var(--font-size-link);
  font-weight: var(--font-weight-body);
  color: var(--color-blue);
  text-decoration: underline;
}

.global-link--underlined a {
  color: var(--color-primary-dark);
  cursor: pointer;
  text-decoration: underline;
  margin-right: 2px;
}

.global-edit-icon img {
  height: 14px;
  width: 14px;
}

.global-text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.content-title__break {
  font-size: 20px;
  font-weight: 600;
  color: #282d32;
  margin: 40px 0 10px;
}

.content-link {
  color: #4a90e2;
}

.content-bold {
  font-weight: 600;
}

.no-padding {
  padding: 0;
}

.notification-link>.link {
  text-decoration: underline;
}

.input-field {
  width: 100%;
  line-height: 30px;
  border: 1px solid #979797;
  padding: 5px 11px;
  border-radius: var(--border-radius);
  font-size: 14px;
  margin-top: 10px;
}

.input-magnifier {
  position: absolute;
  right: 5px;
  top: 19px;
  width: 30px;
  height: 30px;
}

.global-error {
  font-size: 11px;
}